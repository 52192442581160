import { MOBILE_BREAKPOINT } from '@flock/flock-component-library'
import { Box, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'

export type PropertyDetailBackgroundProps = {
  streetViewImageUrl?: string
  backgroundTransition?: boolean
  hideTabletBackground?: boolean
}

const PropertyDetailBackground = (props: PropertyDetailBackgroundProps) => {
  const [mounted, setMounted] = useState(false)
  const { streetViewImageUrl, backgroundTransition } = props
  useEffect(() => {
    setMounted(true)
  }, [])
  const isMobile = useMediaQuery(MOBILE_BREAKPOINT)
  return (
    <>
      <Box
        position="absolute"
        height="100%"
        width={{
          xs: '100vw',
          md: '50vw',
        }}
        left="0px"
        zIndex={-2}
        sx={{
          transition: backgroundTransition ? 'opacity 1s ease' : undefined,
          backgroundImage: `url("${streetViewImageUrl}")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: mounted ? 0.3 : 0,
          position: isMobile ? 'fixed' : 'absolute',
        }}
      />
      <Box
        position="absolute"
        height="100%"
        width={{
          xs: '100vw',
          md: '50vw',
        }}
        left="0px"
        zIndex={-1}
        sx={{
          background: `rgba(235, 246, 255, 0.1);`,
          backdropFilter: `blur(2px)`,
        }}
      />
    </>
  )
}

export default PropertyDetailBackground
